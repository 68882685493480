import axios from "axios";

export default {
  state: {
    data: [],
    loading: true,
  },

  mutations: {
    SET_ACTIVATORS(state, activators) {
      state.data = activators;
      state.loading = false;
    },
    START_LOADING(state) {
      state.loading = true;
    },
  },

  actions: {
    getActivators({ commit }) {
      commit("START_LOADING");
      axios
        .get(`https://${process.env.VUE_APP_API_URL}/activator/all`)
        .then((response) => response.data)
        .then((json) => commit("SET_ACTIVATORS", json));
    },
  },
};
