import Vue from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { Hub, Auth } from "aws-amplify";

import "./assets/style.css";

//window.LOG_LEVEL='DEBUG';

dayjs.extend(dayjsPluginUTC);
dayjs.extend(localizedFormat);

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get() {
      return dayjs;
    },
  },
});

Vue.config.productionTip = false;

Vue.use(VueCookies);

Hub.listen(/.*/, (data) => {
  //console.log('Hub: ' + data.payload.event);
  switch (data.payload.event) {
    case "signIn":
      //console.log('signIn');
      store.dispatch("updateUserSession");
      store.dispatch("getHunted");
      break;
    default:
      break;
  }
});

Auth.configure(
  // https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
  {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'us-east-2:a5ffb622-9b18-4be9-a53d-4af128da5e58',

    // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_AWS_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_AWS_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_AWS_COGNITO_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: window.location.hostname,
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: false,
    },

    // OPTIONAL - customized storage object
    //storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    //authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    //clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: process.env.VUE_APP_AWS_COGNITO_DOMAIN,
      scopes: ["email", "profile"],
      redirectSignIn: window.location.origin + "/",
      redirectSignOut: window.location.origin + "/",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  }
);

Vue.filter("date", function (value) {
  if (value) {
    return dayjs.utc(value).format("YYYY-MM-DD");
  }
});

Vue.filter("dateTime", function (value) {
  if (value) {
    return dayjs.utc(value).format("YYYY-MM-DD HH:mm");
  }
});

Vue.filter("time", function (value) {
  if (value) {
    return dayjs.utc(value).format("HH:mm");
  }
});

Vue.filter("dateTimeCard", function (value) {
  if (value) {
    const seconds = dayjs.utc().diff(dayjs.utc(value), "seconds");
    if (seconds < 0) return "0 sec ago";
    if (seconds < 60) return `${seconds} sec ago`;
    const minutes = Math.floor(seconds / 60);
    if (minutes == 1) {
      return `${minutes} min ago`;
    }
    return `${minutes} mins ago`;
  }
});

Vue.mixin({
  computed: {
    unique() {
      return function (arr, key) {
        var output = [];
        var usedKeys = {};
        for (var i = 0; i < arr.length; i++) {
          if (!usedKeys[arr[i][key]]) {
            usedKeys[arr[i][key]] = true;
            output.push(arr[i]);
          }
        }
        return output;
      };
    },
    quicksort() {
      return function (arr, key) {
        return arr.slice().sort(function (a, b) {
          return a[key] - b[key];
        });
      };
    },
  },
});

Vue.use(VuetifyDialog);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
