import axios from "axios";

import { Auth } from "aws-amplify";

export default {
  state: {
    isAuthenticated: false,
    userId: null,
    callsign: null,
    callsigns: null,
    all_callsigns: null,
    email: null,
    name: null,
    latlong: null,
    token: null,
    groups: [],
    cognito_user: null,
    cognito_email: null,
    isSpotAdmin: false,
    isTechAdmin: false,
    isUserManager: false,
    isUserSupport: false,
    isLogManager: false,
    isLogUploader: false,
    isAlphaUser: false,
    isBetaUser: false,
    stats: null,
    accountStatus: "unknown",
  },

  mutations: {
    SET_USERID(state, userId) {
      state.userId = userId;
    },
    SET_CALLSIGN(state, callsign) {
      state.callsign = callsign;

      if (!state.callsigns) state.callsigns = [callsign];
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    SET_NAME(state, name) {
      state.name = name;
    },
    SET_LATLONG(state, latlong) {
      state.latlong = latlong;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      if (state.token) {
        state.isAuthenticated = true;
      } else {
        // auth token expired? reset permissions
        state.isAuthenticated = false;
        state.isTechAdmin = false;
        state.isUserManager = false;
        state.isSpotAdmin = false;
        state.isUserSupport = false;
        state.isLogManager = false;
        state.isLogUploader = false;
        state.isAlphaUser = false;
        state.isBetaUser = false;
        state.groups = [];
      }
    },
    SET_GROUPS(state, groups) {
      state.groups = groups;
      state.isTechAdmin = groups.includes("TechAdmin");
      state.isUserManager = groups.includes("UserManager") || state.isTechAdmin; // delete + group permissions
      state.isSpotAdmin =
        groups.includes("Spot Moderator") || state.isTechAdmin;
      state.isUserSupport =
        groups.includes("User Support") ||
        state.isUserManager ||
        state.isTechAdmin;
      state.isLogManager = groups.includes("Log Manager") || state.isTechAdmin;
      state.isLogUploader = groups.includes("Upload") || state.isTechAdmin;
      state.isAlphaUser = groups.includes("Alpha") || state.isTechAdmin;
      state.isBetaUser = groups.includes("Beta") || state.isTechAdmin;
    },
    SET_COGNITO_USER(state, user) {
      state.cognito_user = user;
    },
    SET_COGNITO_EMAIL(state, email) {
      state.cognito_email = email;
    },
    SET_STATS(state, stats) {
      state.stats = stats;
    },
    SET_ACCOUNT_STATUS(state, accountStatus) {
      state.accountStatus = accountStatus;

      if (state.accountStatus == "active" && !state.userId)
        state.accountStatus = "refresh";
    },
    SET_CALLSIGNS(state, callsigns) {
      var c = [];

      for (var i = 0; i < callsigns.length; i++) {
        if (callsigns[i].valid) c.push(callsigns[i].callsign);
      }

      state.callsigns = c;
    },
    SET_ALL_CALLSIGNS(state, callsigns) {
      var c = [];

      for (var i = 0; i < callsigns.length; i++) {
        c.push(callsigns[i].callsign);
      }

      state.all_callsigns = c;
    },
  },

  actions: {
    async updateUserSession({ commit, dispatch }) {
      try {
        var data = await Auth.currentSession();

        commit(
          "SET_COGNITO_USER",
          data["idToken"]["payload"]["cognito:username"]
        );
        commit("SET_COGNITO_EMAIL", data["idToken"]["payload"]["email"]);

        var groups = [];
        if ("cognito:groups" in data["idToken"]["payload"])
          groups = groups.concat(data["idToken"]["payload"]["cognito:groups"]);
        if ("pota:groups" in data["idToken"]["payload"])
          groups = groups.concat(
            data["idToken"]["payload"]["pota:groups"].split("|")
          );
        groups = groups.filter((v, i, a) => a.indexOf(v) === i); // remove duplicates
        commit("SET_GROUPS", groups);

        var inactive = data["idToken"]["payload"]["pota:inactive"];
        if (inactive) {
          commit("SET_ACCOUNT_STATUS", "inactive");
          return;
        }

        var id = data["idToken"]["payload"]["pota:id"];
        if (!id) {
          var callsign = data["idToken"]["payload"]["callsign"];
          if (callsign) {
            // old login before POTA prefixes
            console.log("sign out of old session!");
            Auth.signOut();
            return;
          }

          commit("SET_ACCOUNT_STATUS", "unknown");
          commit("SET_TOKEN", data["idToken"]["jwtToken"]);
          dispatch("getAccountStatus");
          return;
        }

        commit("SET_USERID", id);
        commit("SET_CALLSIGN", data["idToken"]["payload"]["pota:callsign"]);
        commit("SET_EMAIL", data["idToken"]["payload"]["pota:email"]);
        commit("SET_NAME", data["idToken"]["payload"]["pota:fullname"]);

        // only an active user should have a valid pota:id
        commit("SET_ACCOUNT_STATUS", "active");

        commit("SET_TOKEN", data["idToken"]["jwtToken"]);

        dispatch("getAuthUserStats");
        dispatch("getCallsigns");
        dispatch("getHunted");
        dispatch("getSpots");
      } catch (error) {
        // not signed in
        console.log(error);
        commit("SET_TOKEN", null);
      }
    },
    updateAuthToken({ commit }) {
      //console.log('refresh session')
      // Auth.currentSession is supposed to refresh the token if needed
      Auth.currentSession()
        .then((data) => {
          commit("SET_TOKEN", data["idToken"]["jwtToken"]);
        })
        .catch((error) => {
          // not signed in
          console.log(error);
          commit("SET_TOKEN", null);
        });
    },
    getAuthUserStats({ commit, rootState }) {
      if (rootState.user.callsign == null) return;

      var sanitizedCallsign = rootState.user.callsign.match(/^[A-Z\d]+/);

      axios
        .get(
          `https://${process.env.VUE_APP_API_URL}/stats/user/${sanitizedCallsign}`
        )
        .then(
          (response) => {
            commit("SET_STATS", response.data);
          },
          (error) => {
            console.log(error);
            this.notfound = true;
          }
        );
    },
    getAccountStatus({ commit, getters, rootState }) {
      if (!rootState.user.isAuthenticated) return;

      axios
        .get(
          `https://${process.env.VUE_APP_API_URL}/user/account/status`,
          getters.authTokenHeader
        )
        .then(
          (response) => {
            //console.log(response.data)
            commit("SET_ACCOUNT_STATUS", response.data);
          },
          (error) => {
            console.log(error); // Now error is used
            this.notfound = true;
          }
        );
    },
    getCallsigns({ commit, getters, rootState, dispatch }) {
      if (!rootState.user.isAuthenticated) return;

      axios
        .get(
          `https://${process.env.VUE_APP_API_URL}/user/callsigns`,
          getters.authTokenHeader
        )
        .then((response) => response.data)
        .then((json) => {
          commit("SET_CALLSIGNS", json);
          commit("SET_ALL_CALLSIGNS", json);
          dispatch("updateFilteredSpots"); // spots.js; better way to do this?
        });
    },
  },
};
