export default {
  state: {
    center_latlong: null,
    zoom: null,
    pins_url: null,
  },

  mutations: {
    SET_CENTER_LATLONG(state, center_latlong) {
      state.center_latlong = center_latlong;
    },
    SET_ZOOM(state, zoom) {
      state.zoom = zoom;
    },
    SET_PINS_URL(state, pins_url) {
      state.pins_url = pins_url;
    },
  },
};
