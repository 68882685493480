import axios from "axios";
import dayjs from "dayjs";

export default {
  state: {
    data: [],
    filtered: [],
    loading: true,
    period: 365,
    sort: "Start Date/Time",
  },

  mutations: {
    SET_ACTIVATIONS(state, activations) {
      state.data = activations;
      state.loading = false;

      this.dispatch("updateFilteredActivations");
    },
    START_LOADING(state) {
      state.loading = true;
    },
    SET_ACTIVATIONS_PERIOD(state, days) {
      if (days == null) days == 365;
      state.period = days;

      this.dispatch("updateFilteredActivations");
    },
    SET_ACTIVATIONS_SORT(state, sort) {
      if (sort == null) sort == "Start Date/Time";
      state.sort = sort;

      this.dispatch("updateFilteredActivations");
    },
    SET_FILTERED_ACTIVATIONS(state, activations) {
      state.filtered = activations;
    },
  },

  actions: {
    updateFilteredActivations({ commit, rootState }) {
      var state = rootState.activations;
      var activations = Array.from(state.data);

      if (state.period > 0 && state.period < 365) {
        var endDate = dayjs.utc().add(state.period, "day");
        activations = activations.filter(
          (x) => dayjs.utc(x.startDate) <= endDate
        );
      }

      if (state.sort == "Activator")
        activations.sort((a, b) => a.activator.localeCompare(b.activator));
      else if (state.sort == "Reference")
        activations.sort((a, b) => a.reference.localeCompare(b.reference));
      else if (state.sort == "Park Name")
        activations.sort((a, b) => a.name.localeCompare(b.name));
      else if (state.sort == "Region")
        activations.sort((a, b) =>
          a.locationDesc.localeCompare(b.locationDesc)
        );

      commit("SET_FILTERED_ACTIVATIONS", activations);
    },
    getActivations({ commit }) {
      commit("START_LOADING");
      axios
        .get(`https://${process.env.VUE_APP_API_URL}/activation`)
        .then((response) => response.data)
        .then((json) => commit("SET_ACTIVATIONS", json));
    },
    getActivationsv1({ commit }) {
      commit("START_LOADING");
      axios
        .get(`https://${process.env.VUE_APP_API_URL}/v1/activations`)
        .then((response) => response.data)
        .then((json) => commit("SET_ACTIVATIONS", json));
    },
  },
};
