// The following two fields are generated by adif_spec.py and need to be updated whenever that file is updated.
var adifBands = [
  {
    band: "2190m",
    low: 0.1357,
    high: 0.1378,
    hide: true,
  },
  {
    band: "630m",
    low: 0.472,
    high: 0.479,
    hide: true,
  },
  {
    band: "560m",
    low: 0.501,
    high: 0.504,
    hide: true,
  },
  {
    band: "160m",
    low: 1.8,
    high: 2.0,
    hide: false,
  },
  {
    band: "80m",
    low: 3.5,
    high: 4.0,
    hide: false,
  },
  {
    band: "60m",
    low: 5.06,
    high: 5.45,
    hide: false,
  },
  {
    band: "40m",
    low: 7.0,
    high: 7.3,
    hide: false,
  },
  {
    band: "30m",
    low: 10.1,
    high: 10.15,
    hide: false,
  },
  {
    band: "20m",
    low: 14.0,
    high: 14.35,
    hide: false,
  },
  {
    band: "17m",
    low: 18.068,
    high: 18.168,
    hide: false,
  },
  {
    band: "15m",
    low: 21.0,
    high: 21.45,
    hide: false,
  },
  {
    band: "12m",
    low: 24.89,
    high: 24.99,
    hide: false,
  },
  {
    band: "10m",
    low: 28.0,
    high: 29.7,
    hide: false,
  },
  {
    band: "8m",
    low: 40.0,
    high: 45.0,
    hide: true,
  },
  {
    band: "6m",
    low: 50.0,
    high: 54.0,
    hide: false,
  },
  {
    band: "5m",
    low: 54.000001,
    high: 69.9,
    hide: true,
  },
  {
    band: "4m",
    low: 70.0,
    high: 71.0,
    hide: false,
  },
  {
    band: "2m",
    low: 144.0,
    high: 148.0,
    hide: false,
  },
  {
    band: "1.25m",
    low: 222.0,
    high: 225.0,
    hide: false,
  },
  {
    band: "70cm",
    low: 420.0,
    high: 450.0,
    hide: false,
  },
  {
    band: "33cm",
    low: 902.0,
    high: 928.0,
    hide: true,
  },
  {
    band: "23cm",
    low: 1240.0,
    high: 1300.0,
    hide: true,
  },
  {
    band: "13cm",
    low: 2300.0,
    high: 2450.0,
    hide: true,
  },
  {
    band: "9cm",
    low: 3300.0,
    high: 3500.0,
    hide: true,
  },
  {
    band: "6cm",
    low: 5650.0,
    high: 5925.0,
    hide: true,
  },
  {
    band: "3cm",
    low: 10000.0,
    high: 10500.0,
    hide: true,
  },
  {
    band: "1.25cm",
    low: 24000.0,
    high: 24250.0,
    hide: true,
  },
  {
    band: "6mm",
    low: 47000.0,
    high: 47200.0,
    hide: true,
  },
  {
    band: "4mm",
    low: 75500.0,
    high: 81000.0,
    hide: true,
  },
  {
    band: "2.5mm",
    low: 119980.0,
    high: 123000.0,
    hide: true,
  },
  {
    band: "2mm",
    low: 134000.0,
    high: 149000.0,
    hide: true,
  },
  {
    band: "1mm",
    low: 241000.0,
    high: 250000.0,
    hide: true,
  },
  {
    band: "submm",
    low: 275000.0,
    high: 30000000.0,
    hide: true,
  },
];

var adifModes = [
  {
    mode: "AM",
    submodes: [],
    importonly: false,
    pota: "PHONE",
  },
  {
    mode: "ARDOP",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "ATV",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "CHIP",
    submodes: ["CHIP64", "CHIP128"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "CLO",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "CONTESTI",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "CW",
    submodes: ["PCW"],
    importonly: false,
    pota: "CW",
  },
  {
    mode: "DIGITALVOICE",
    submodes: ["C4FM", "DMR", "DSTAR"],
    importonly: false,
    pota: "PHONE",
  },
  {
    mode: "DOMINO",
    submodes: ["DOMINOEX", "DOMINOF"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "DYNAMIC",
    submodes: ["VARA HF", "VAR SATELLITE", "VARA FM 1200", "VARA FM 9600"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "FAX",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "FM",
    submodes: [],
    importonly: false,
    pota: "PHONE",
  },
  {
    mode: "FSK441",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "FT8",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "HELL",
    submodes: ["FMHELL", "FSKHELL", "HELL80", "HFSK", "PSKHELL"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "ISCAT",
    submodes: ["ISCAT-A", "ISCAT-B"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "JT4",
    submodes: ["JT4A", "JT4B", "JT4C", "JT4D", "JT4E", "JT4F", "JT4G"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "JT6M",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "JT9",
    submodes: [
      "JT9-1",
      "JT9-2",
      "JT9-5",
      "JT9-10",
      "JT9-30",
      "JT9A",
      "JT9B",
      "JT9C",
      "JT9D",
      "JT9E",
      "JT9E FAST",
      "JT9F",
      "JT9F FAST",
      "JT9G",
      "JT9G FAST",
      "JT9H",
      "JT9H FAST",
    ],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "JT44",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "JT65",
    submodes: ["JT65A", "JT65B", "JT65B2", "JT65C", "JT65C2"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "MFSK",
    submodes: [
      "FSQCALL",
      "FT4",
      "JS8",
      "MFSK4",
      "MFSK8",
      "MFSK11",
      "MFSK16",
      "MFSK22",
      "MFSK31",
      "MFSK32",
      "MFSK64",
      "MFSK128",
      "FST4",
      "Q65",
    ],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "MSK144",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "MT63",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "OLIVIA",
    submodes: [
      "OLIVIA 4/125",
      "OLIVIA 4/250",
      "OLIVIA 8/250",
      "OLIVIA 8/500",
      "OLIVIA 16/500",
      "OLIVIA 16/1000",
      "OLIVIA 32/1000",
    ],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "OPERA",
    submodes: ["OPERA-BEACON", "OPERA-QSO"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "PAC",
    submodes: ["PAC2", "PAC3", "PAC4"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "PAX",
    submodes: ["PAX2"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "PKT",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "PSK",
    submodes: [
      "FSK31",
      "PSK10",
      "PSK31",
      "PSK63",
      "PSK63F",
      "PSK125",
      "PSK250",
      "PSK500",
      "PSK1000",
      "PSKAM10",
      "PSKAM31",
      "PSKAM50",
      "PSKFEC31",
      "QPSK31",
      "QPSK63",
      "QPSK125",
      "QPSK250",
      "QPSK500",
      "SIM31",
    ],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "PSK2K",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "Q15",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "QRA64",
    submodes: ["QRA64A", "QRA64B", "QRA64C", "QRA64D", "QRA64E"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "ROS",
    submodes: ["ROS-EME", "ROS-HF", "ROS-MF"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "RTTY",
    submodes: ["ASCI"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "RTTYM",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "SSB",
    submodes: ["LSB", "USB"],
    importonly: false,
    pota: "PHONE",
  },
  {
    mode: "SSTV",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "T10",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "THOR",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "THRB",
    submodes: ["THRBX"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "TOR",
    submodes: ["AMTORFEC", "GTOR"],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "V4",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "VOI",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "WINMOR",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "WSPR",
    submodes: [],
    importonly: false,
    pota: "DATA",
  },
  {
    mode: "AMTORFEC",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "ASCI",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "C4FM",
    submodes: [],
    importonly: true,
    alt: {
      mode: "DIGITALVOICE",
      submode: "C4FM",
    },
    pota: "DATA",
  },
  {
    mode: "CHIP64",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "CHIP128",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "DOMINOF",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "DSTAR",
    submodes: [],
    importonly: true,
    alt: {
      mode: "DIGITALVOICE",
      submode: "DSTAR",
    },
    pota: "DATA",
  },
  {
    mode: "FMHELL",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "FSK31",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "GTOR",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "HELL80",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "HFSK",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT4A",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT4B",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT4C",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT4D",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT4E",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT4F",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT4G",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT65A",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT65B",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "JT65C",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "MFSK8",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "MFSK16",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PAC2",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PAC3",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PAX2",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PCW",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSK10",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSK31",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSK63",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSK63F",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSK125",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSKAM10",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSKAM31",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSKAM50",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSKFEC31",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "PSKHELL",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "QPSK31",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "QPSK63",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "QPSK125",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
  {
    mode: "THRBX",
    submodes: [],
    importonly: true,
    pota: "DATA",
  },
];

export default {
  state: {
    bands: adifBands,
    modes: adifModes,

    // POTA groups things into 3 main modes
    potaModes: ["CW", "DATA", "PHONE"],

    // Bands to list for user. Other bands can be uploaded, but we won't clutter the lists with them.
    potaBands: adifBands
      .filter((band) => !band.hide)
      .map((band) => band.band.toUpperCase()), // not sure why we use upper case everywhere

    allBands: adifBands.map((band) => band.band.toUpperCase()), // not sure why we use upper case everywhere

    // this is actually an amalgmation of modes and submodes
    allSubmodes: adifModes
      .filter((mode) => !mode.importonly)
      .map((mode) => [mode.mode, mode.submodes].flat())
      .flat(),

    // Dictionary to convert mode/submode to POTA mode
    adifToPota: adifModes
      .filter((mode) => !mode.importonly)
      .map((mode) =>
        [
          { mode: mode.mode, pota: mode.pota },
          mode.submodes.map((submode) => ({ mode: submode, pota: mode.pota })),
        ].flat()
      )
      .flat()
      .reduce((acc, cur) => ({ ...acc, [cur.mode]: cur.pota }), {}),

    submodeList: adifModes
      .filter((mode) => !mode.importonly)
      .map((mode) =>
        [
          {
            name: mode.mode,
            value: mode.mode,
            mode: mode.mode,
            pota: mode.pota,
          },
          mode.submodes.map((submode) => ({
            name: `— ${submode}`,
            value: `${mode.mode}:${submode}`,
            mode: mode.mode,
            submode: submode,
            pota: mode.pota,
          })),
        ].flat()
      )
      .flat(),

    cwModes: adifModes
      .filter((mode) => !mode.importonly && mode.pota === "CW")
      .map((mode) => [mode.mode, mode.submodes].flat())
      .flat(),

    dataModes: adifModes
      .filter((mode) => !mode.importonly && mode.pota === "DATA")
      .map((mode) => [mode.mode, mode.submodes].flat())
      .flat(),

    phoneModes: adifModes
      .filter((mode) => !mode.importonly && mode.pota === "PHONE")
      .map((mode) => [mode.mode, mode.submodes].flat())
      .flat(),
  },
};
