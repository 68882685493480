import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

import {
  mdiAccount,
  mdiAccountCircle,
  mdiAccountPlusOutline,
  mdiArrowLeft,
  mdiArrowRight,
  mdiAutorenew,
  mdiBook,
  mdiBookOpenVariant,
  mdiCalendar,
  mdiCalendarPlus,
  mdiCardAccountDetailsOutline,
  mdiCheck,
  mdiCity,
  mdiClipboardAccount,
  mdiClipboardText,
  mdiClockTimeFour,
  mdiClockTimeFourOutline,
  mdiClose,
  mdiCloudDownload,
  mdiCloudUpload,
  mdiCommentCheck,
  mdiCommentCheckOutline,
  mdiDownload,
  mdiEarth,
  mdiFileDocumentOutline,
  mdiFileUploadOutline,
  mdiFolderMarker,
  mdiGauge,
  mdiHistory,
  mdiHome,
  mdiInformationOutline,
  mdiLibrary,
  mdiLicense,
  mdiLifebuoy,
  mdiLoginVariant,
  mdiLogoutVariant,
  mdiMagnify,
  mdiMap,
  mdiMapMarker,
  mdiMapMarkerMultipleOutline,
  mdiMapMarkerPlusOutline,
  mdiMapMarkerRadius,
  mdiMenuDown,
  mdiMessageBulleted,
  mdiNotebookEditOutline,
  mdiNotebookOutline,
  mdiPause,
  mdiPlay,
  mdiPlus,
  mdiRadar,
  mdiRadioTower,
  mdiTerrain,
  mdiVideoInputAntenna,
} from "@mdi/js";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
    values: {
      mdiAccount,
      mdiAccountCircle,
      mdiAccountPlusOutline,
      mdiArrowLeft,
      mdiArrowRight,
      mdiAutorenew,
      mdiBook,
      mdiBookOpenVariant,
      mdiCalendar,
      mdiCalendarPlus,
      mdiCardAccountDetailsOutline,
      mdiCheck,
      mdiCity,
      mdiClipboardAccount,
      mdiClipboardText,
      mdiClockTimeFour,
      mdiClockTimeFourOutline,
      mdiClose,
      mdiCloudDownload,
      mdiCloudUpload,
      mdiCommentCheck,
      mdiCommentCheckOutline,
      mdiDownload,
      mdiEarth,
      mdiFileDocumentOutline,
      mdiFileUploadOutline,
      mdiFolderMarker,
      mdiGauge,
      mdiHistory,
      mdiHome,
      mdiInformationOutline,
      mdiLibrary,
      mdiLicense,
      mdiLifebuoy,
      mdiLoginVariant,
      mdiLogoutVariant,
      mdiMagnify,
      mdiMap,
      mdiMapMarker,
      mdiMapMarkerMultipleOutline,
      mdiMapMarkerPlusOutline,
      mdiMapMarkerRadius,
      mdiMenuDown,
      mdiMessageBulleted,
      mdiNotebookEditOutline,
      mdiNotebookOutline,
      mdiPause,
      mdiPlay,
      mdiPlus,
      mdiRadar,
      mdiRadioTower,
      mdiTerrain,
      mdiVideoInputAntenna,
    },
  },
  theme: {
    themes: {
      light: {
        primary: colors.green.darken1,
        anchor: colors.green.darken3,
        dim: colors.grey.lighten3,
        highlight: colors.green.lighten4,
        new: colors.lightBlue.lighten5,
        normal: colors.transparent,
      },
      dark: {
        primary: colors.green.darken1,
        anchor: colors.green.darken1,
        dim: colors.grey.darken3,
        highlight: colors.green.darken4,
        new: colors.blueGrey.darken2,
        normal: colors.transparent,
      },
    },
    options: {
      customProperties: true,
    },
    // dark: true
  },
});

// Former POTA green - '#4caf50'

// export default new Vuetify({
//     icons: {
//         iconfont: 'mdi', // default - only for display purposes
//     },
//     theme: {
//         themes: {
//             light: {
//                 primary: '#4caf50',
//                 anchor: colors.green.darken2,
//             }
//         },
//         // dark: true
//     }
// })

// export default new Vuetify({
//     icons: {
//         iconfont: 'mdi', // default - only for display purposes
//     },
//     theme: {
//         themes: {
//             light: {
//                 primary: colors.purple,
//                 secondary: colors.indigo,
//                 // All keys will generate theme styles,
//                 // Here we add a custom `tertiary` color
//                 tertiary: colors.pink.base,
//             }
//         },
//         dark: true
//     }
// })

// primary: '#4caf50'

// export default {
//     primary: {
//       base: colors.purple.base,
//       darken1: colors.purple.darken2,
//     },
//     secondary: colors.indigo,
//     // All keys will generate theme styles,
//     // Here we add a custom `tertiary` color
//     tertiary: colors.pink.base,
//   }

//   {
//     primary: {
//       base: colors.purple.base,
//       darken1: colors.purple.darken2,
//     },
//     secondary: colors.indigo,
//     // All keys will generate theme styles,
//     // Here we add a custom `tertiary` color
//     tertiary: colors.pink.base,
//   }
