<template>
  <div
    v-if="
      accountStatus == 'inactive' ||
      (isAuthenticated && accountStatus != 'active')
    "
  >
    <v-snackbar
      :value="accountStatus == 'refresh'"
      color="success"
      max-width="90%"
      app
      bottom
      rounded
      timeout="-1"
      ><b>Congratulations!</b> Your POTA account has been approved and is now
      active. You will need to sign out and sign in again to use your account.
      <a class="black--text" @click="doSignOut"
        ><b>Click here to sign out.</b></a
      ></v-snackbar
    >
    <v-snackbar
      :value="accountStatus == 'not_found'"
      color="orange accent-1 black--text"
      max-width="90%"
      app
      bottom
      rounded
      timeout="-1"
      >You are currently logged in but you have not completed the signup
      process.
      <router-link to="/signup"
        ><b>Click here to complete the sign up process.</b></router-link
      ></v-snackbar
    >
    <v-snackbar
      :value="accountStatus == 'pending'"
      color="orange accent-1 black--text"
      max-width="90%"
      app
      bottom
      rounded
      timeout="-1"
      >Your account is currently pending approval by the POTA team. While you
      are waiting, make sure you review the
      <a href="https://docs.pota.app" target="_blank">POTA Documentation</a>
      website and also watch
      <a
        href="https://www.youtube.com/watch?v=ROC4DsDr0-s&amp;list=PLd_DTtq1stJIZV6dcHypXBUq0xEYigBQf"
        target="_blank"
        >Activator</a
      >
      and
      <a
        href="https://www.youtube.com/watch?v=KBwOaJ-tvpY&amp;list=PLd_DTtq1stJKvZC3rjLncxB0TM72fvZ1n"
        target="_blank"
        >Hunter</a
      >
      courses on YouTube if you have not already done so. This page will
      automatically let you know when your account is ready to use!</v-snackbar
    >
    <v-snackbar
      :value="accountStatus == 'rejected'"
      color="red accent-1 black--text"
      max-width="90%"
      app
      bottom
      rounded
      timeout="-1"
      >Your account request has been rejected for approval by the POTA team
      because there is an existing account for this callsign with a different
      email address and we were unable to confirm your ownership of both
      accounts. If you still have access to your old sign-in information, your
      can still access and use your existing POTA account. If you do not have
      access to your old sign-in information or if you would prefer to use a
      different email address, please email the POTA support team at
      <a href="mailto:help@parksontheair.com">help@parksontheair.com</a> for
      additional help.</v-snackbar
    >
    <v-snackbar
      :value="accountStatus == 'inactive'"
      color="red accent-1 black--text"
      max-width="90%"
      app
      bottom
      rounded
      timeout="-1"
      >Your account has been administratively deactivated.</v-snackbar
    >
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "AccountStatus",
  computed: {
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated;
      },
    },
    accountStatus: {
      get() {
        return this.$store.state.user.accountStatus;
      },
    },
  },
  methods: {
    doSignOut: function () {
      Auth.signOut();
    },
  },
  data() {
    return {
      refreshCountdown: 300, // check for updated status every 5 minutes
      refreshTimer: null,
    };
  },
  watch: {
    refreshCountdown: {
      handler(value) {
        if (value == 0) {
          if (this.accountStatus == "active") {
            if (this.refreshTimer != null) {
              clearInterval(this.refreshTimer);
              this.refreshTimer = null;
            }
            return;
          }
          this.$store.dispatch("getAccountStatus");
          value = 300;
        }

        if (this.refreshTimer == null && this.accountStatus != "active") {
          this.refreshTimer = setInterval(() => {
            this.refreshCountdown--;
          }, 1000);
        }
      },
      immediate: true, // start on creation
    },
    accountStatus: function () {
      // eslint-disable-line no-unused-vars
      if (this.isAuthenticated && this.accountStatus == "not_found")
        this.$router.push("signup"); // user hasn't registered; send to sign up
    },
  },
  beforeDestroy() {
    if (this.refreshTimer != null) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
  },
};
</script>
